/* All Projects CSS */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}
:root {
  --primary: #366ba1;
  --dark: #232323;
  --pure: #fff;
  --smoke: whitesmoke;

  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: #ffea00;
  --secondary-dark: #e3d002;
  --overlay: rgba(0, 0, 0, 0.7);
  /* --primary-green: #02daa5; */
  --primary-green: #00ffc0;
  --secondary-green: #03a2b4;
  --green: #2eaf7d;
  --primary-light-blue: #2eafbe;
  --light-blue: #c1f6ed;
  --dark-green: #449342;
  --dark-blue: #02353c;
  --primary-blue: #3fd0c9;

  /*for About Us*/
  --font-family: "Manrope", sans-serif;

  --gradient-bar: linear-gradient(
    90deg,
    rgba(46, 175, 125, 1) 0%,
    rgba(244, 152, 103, 1) 87%
  );

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  /* --color-subtext: #ff8a71; */
  --color-subtext: rgb(46, 175, 125);
}
.gradient__text {
  background: var(--color-subtext);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
body {
  font-family: "Bungee Inline", cursive;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.floating-share {
  position: relative;
  z-index: 9999;
}

.share-btn {
  background-color: transparent;
  border: none;
  color: #4caf50;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 23px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.share-btn i {
  margin-right: 5px;
}

.share-icons {
  display: none;
  position: absolute;
  bottom: 0px;
  right: 50px;
  z-index: 999;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.share-icon {
  display: inline-block;
  background-color: #eee;
  color: #333;
  padding: 5px 10px;
  margin-right: 5px;
  text-decoration: none;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.share-icon i {
  /* margin-right: 5px; */
}

.share-icon:hover {
  background-color: #4caf50;
  color: white;
}

@media only screen and (max-width: 600px) {
  /* .floating-share {
    bottom: 10px;
    right: 10px;
  } */

  /* .share-icons {
    bottom: 40px;
  } */
}

.active {
  display: flex;
}

.modal-body h3 {
  font-weight: 600;
  padding-bottom: 0.8em;
}
