/**************banner section******************/

.carousel {
  margin-top: 90px;
}

.carousel-item {
  height: 100vh;
  background: no-repeat scroll center scroll;
  object-fit: cover;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #080d15;
  opacity: 0.7;
}

.hero {
  position: absolute;
  top: 20%;
  left: 20%;
  bottom: 20%;
  right: 20%;
  z-index: 3;
  text-align: center;
}

.hero .para {
  padding-top: 100px;
  font-size: 19px;
  font-weight: bold;
  color: #008080;
}

.hero .para i {
  font-size: 20px;
  font-weight: bold;
  color: #014d4e;
}

.hero .para2 {
  font-size: 19px;
  font-weight: bold;
  color: #008080;
}

.hero a {
  animation: wiggle 200s linear infinite;
}

.hero h3 {
  padding-top: 50px;
}

.hero small,
br {
  font-size: 16px;
  font-weight: 500;
  color: white;
}
.hero img:hover {
  transform: translateY(-20px);
}

.herobg img {
  height: max-content;
}
.herobg {
  display: flex;
  height: inherit;
  align-items: center;
  position: absolute;
}

@media (max-width: 979px) {
  .slide .carousel-inner .carousel-item img {
    width: 100%;
    height: auto;
    min-height: 620px;
  }
  /* .row {
    justify-content: rigth;
  } */
}

@media (max-width: 480px) {
  .slide .carousel-inner .carousel-item img {
    min-height: 300px;
  }
  .carousel-indicators [data-bs-target] {
    width: 5px !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 8px !important;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-color: #080d15;
    opacity: 0.7;
  }
  .carousel-item {
    height: 80vh;
  }
}

.carousel-inner .btn-complaint {
  top: 68%;
  left: 10%;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
  transform: scale(1.2);
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}
