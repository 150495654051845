.track-complaint h1 {
  font-size: revert;
  font-weight: 700;
}

.track-complaint table {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 20px; */
  z-index: 10;
  box-shadow: inset 0px 0px 5px 0px;
}

.track-complaint th,
.track-complaint td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  text-align: center;
  text-align: -webkit-center;
}

.track-complaint th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.track-complaint tr {
  background-color: #f2f2f2;
}

.track-complaint .label {
  font-weight: bold;
  display: none;
}

.track-complaint .status-icon {
  display: inherit;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-weight: bold;
  line-height: 30px;
  /* cursor: pointer; */
  transition: all 0.2s ease-in-out;
  box-shadow: 4px 4px 7px 0px darkcyan;
  padding: 0.2em 1em;
}

.track-complaint .status-icon {
  background-color: #5cb85c;
}

.track-complaint .status-icon.pending {
  background-color: #f0ad4e;
}

.track-complaint .status-icon.disabled {
  opacity: 0.5;
  cursor: default;
}

.track-complaint .report-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #555;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.track-complaint .report-icon.disabled {
  opacity: 0.5;
  cursor: default;
}

.track-complaint .report-icon i {
  font-size: 20px;
  cursor: pointer;
}

.track-complaint .case-search-input {
  width: 100%;
    padding: 10px;
}

.track-complaint .float{
  background-color: white;
  color: #009688;
  box-shadow: 0px -1px 11px 0px white;
}

.track-complaint .about_main {
  padding: 5em 0em;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 768px) {
  .track-complaint   th,
  .track-complaint   td {
    display: block;
    width: 100%;
    padding: 10px 0;
  }

  .track-complaint   td {
    border-bottom: 1px solid #ddd;
    text-align: -webkit-center;
  }

  .track-complaint  tr:nth-child() {
    background-color: white;
  }

  .track-complaint   .status-icon {
    width: max-content;
    border-radius: 20px;
    font-size: 16px;
  }

  .track-complaint .report-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .track-complaint .report-icon i {
    font-size: 24px;
  }
  .track-complaint .label {
    display: block;
  }
  .track-complaint thead tr:nth-child(2) {
    display: none;
  }
  .track-complaint tr {
    border: 2px solid black;
    margin-bottom: 1em;
    display: block;
  }

  .track-complaint .about_main {
    flex-direction: column;
    align-items: center;
  }
}