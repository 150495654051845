@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Montserrat:400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);

/*/ start count stats /*/

section#counter-stats {
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
  padding-top: 2rem;
  /* background: whitesmoke; */
}
/* 
.location {
  display: flex;
  justify-content: space-around;
    flex-direction: column;
} */
.stats {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  position: relative;
  z-index: 10;
  /* scale: .5; */
  /* width: 30%; */
  margin-top: -13px;
  padding: 1em;
}

.stats .fa {
  color: var(--primary-dark);
  font-size: 50px;
}

.stats h5 {
  font-weight: 600;
  font-size: 22px;
}

.col-lg-3 h1 {
  font-weight: 600;
  font-size: 55px;
}
.counter_row {
  display: flex;
  justify-content: flex-end;
}

.stats:nth-child(2) {
  border-right: 5px solid var(--bs-indigo);
  border-left: 5px solid var(--bs-indigo);
}

/*/ end count stats /*/

.location_heading {
  padding-bottom: 1rem;
}

.hex-bg {
  z-index: -2;
  position: relative;
  height: 16em;
  z-index: -2;
  position: absolute;
  /* height: 16em; */
  margin-top: 7em;
}

.about_sec .rw {
  display: inline-flex;
  margin-left: -50px;
  margin-top: -32px;
  /* overflow: hidden; */
}
.about_sec .rw:nth-child(even) {
  margin-left: 1px;
}

/* Deprecated */
/* .about_sec .line2 .hexagon:nth-child(4) {
  z-index: 20;
    -webkit-transform: scale(2); 
  -moz-transform: scale(2); 
  -o-transform: scale(2);
  -ms-transform: scale(2); 
  transform: scale(2); 
    background-color: #2eaf7d8f;
} */

.hexagon {
  position: relative;
  height: 110px;
  width: 100px;
  background: #b4dfcb;
  margin: 1px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: 2s;
  /* opacity: .7; */
}
.hexagon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.02);
}

.cnt-img {
  width: auto;
  position: absolute;
  height: -webkit-fill-available;
  /* display: flex; */
  left: 0;
}

@media screen and (max-width: 1000px) {
  .stats .fa {
    color: var(--primary-dark);
    font-size: 40px;
  }

  .stats h1 {
    font-size: 30px;
  }

  .stats h5 {
    font-size: 20px;
  }
  .sec {
    padding-top: 2rem;
  }
  .stats {
    padding-bottom: 1.5rem;
  }
  .counter_row {
    padding-left: 0rem;
    padding-right: 0rem;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .cnt-img {
    position: relative;
    margin-left: 8em;
    margin-right: 8em;
    height: 18em;
  }
  .stats:nth-child(2) {
    border: none;
    border-top: 5px solid var(--bs-indigo);
    border-bottom: 5px solid var(--bs-indigo);
  }
}
