.row {
  justify-content: space-around;
  margin: 0px;
}

.footer {
  box-shadow: -20px 20px 20px 15px;
  position: absolute;
  background-color: #004d40;
  opacity: 0.8;
  width: 100%;
}

.footer-div {
  margin: 1em;
  margin-bottom: 0;
  padding: unset;
}

.footersection {
  width: 100%;
  height: auto;
  margin: 70px 0 0 0;
}

.footersection p {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
  width: max-content;
}

.footersection li {
  list-style-type: none !important;
}

.footersection li a {
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
}

.footersection li a:hover {
  color: var(--secondary-dark);
}

.footersection .footerdiv a {
  animation: wiggle 2s linear infinite;
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}

.footersection h3 {
  text-transform: uppercase;
  color: var(--primary-green);
  margin-bottom: 25px;
  font-size: 19px;
  font-weight: 700;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.copyright {
  padding: 0.9em;
}

.footer-bottom {
  padding: 0px;
}

.footer-bottom p {
  width: auto;
}

.footer-bottom .row {
  width: auto;
  background-color: #333333;
  opacity: 1;
  padding: 0.9em;
}

.social {
  display: flex;
  justify-content: space-around;
  width: auto;
  padding-right: 7em;
  color: black;
}
.social a {
  margin-top: 2em;
  padding-left: 2em;
}

/***************media quries**********/
@media (max-width: 820px) {
  .carousel-indicators [data-bs-target] {
    width: 5px !important;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 8px !important;
  }
  .hero .para {
    padding-top: 0px;
    font-size: 12px;
    font-weight: bold;
    color: #008080;
  }
  .hero .para i {
    font-size: 10px;
    font-weight: bold;
    color: #014d4e;
  }

  .hero small,
  br {
    font-size: 10px;
    font-weight: 500;
    color: white;
  }
  .hero img {
    padding: 25px 50px 353px 35px;
  }

  .footersection p {
    color: #fff;
    font-weight: 500;
    /* justify-content: center; */
    text-align: center;
    width: auto;
  }

  .footersection li a {
    line-height: 1.6;
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    text-transform: capitalize;
  }

  .footersection li {
    text-align: center;
  }
  .footerdiv a {
    font-size: 10px !important;
    margin-left: 50px !important;
  }

  .copyright {
    padding-right: 50px;
  }

  .footersection h3 {
    text-transform: uppercase;
    text-align: center;
  }

  .footer-div {
    margin: 0px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  /* .footersection .col-md-6 .text-end a {
    padding-right: 91px;
  } */
  .social {
    justify-content: space-evenly;
    padding: 0px;
    justify-content: center;
  }
  .social a {
    padding: 1em;
  }
  .footer-div:nth-child(2) {
    display: none;
  }
}
