.faq-img {
  max-height: 60em;
  max-width: 40em;
  align-self: center;
  padding: 3em;
}

.font-semibold {
  font-size: medium;
}

.c-faq__answer {
  position: absolute;
  opacity: 0;
  z-index: -1;
  box-shadow: 3px 3px 6px 0px;
}

.faq .container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3em;
}

.section__headline {
  font-family: 'Varela Round', sans-serif;
  font-size: 62px;
  font-weight: light;
  color: #1e88e5;
  padding-left: 15px;
  padding-top: 30px;
}

.c-faqs__headline {
  font-family: 'Varela Round', sans-serif;
  text-align: left;
  padding-left: 15px;
  font-size: 1.5em;
  margin-top: 1.5em;
  font-weight: bold;
}

.c-faqs {
  margin: 15px 0;
  padding: 0 15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  display: flex;
  flex-wrap: wrap;
}

.c-faq {
  font-family: 'Varela Round', sans-serif;
  list-style: none;
  margin: 10px 0 5px;
}

.c-faq__title {
  cursor: pointer;
  font-weight: 500;
  background: white;
  z-index: 10;
  position: relative;
  border-radius: 6px;
  font-size: 1.1em;
}
.c-faq__title:hover {
  text-decoration: underline;
}
.c-faq__title::after {
  white-space: nowrap;
  font-weight: 300;
  padding-left: 5px;
  opacity: 0;
  transform-origin: 11px;
  transform: rotateZ(90deg);
  display: none;
  content: '>';
}

.c-faq--active .c-faq__title {
  padding: 0.5em;
  box-shadow: 0px 0px 6px 0px;
  transition: cubic-bezier(0.41, 0.4, 0, 1.54) 0.5s;
  color: #7371ee;
}
.c-faq--active .c-faq__title::after {
  opacity: 1;
  transform: rotateZ(90deg);
  display: inline-block;
}

.c-faq__answer {
  font-weight: normal;
  margin-top: -10%;
  transition: all 0.1s;
  z-index: 100;
  font-size: 0.9em;
  color: #505050;
}

.c-faq--active .c-faq__answer {
  opacity: 1;
  position: relative;
  top: 0;
  left: 0;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 10px;
  transition: all 0.2s;
  border-radius: 3px;
  border: 1px solid #f1f2f3;
  border-top: 1px solid #1e88e5;
  font-size: revert;
  padding: 20px;
  z-index: 100;
  background-color: #f1f2f3;
}

@media (min-width: 780px) {
  .c-faqs {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: auto;
  }
  .c-faqs::before {
    opacity: 0.2;
  }
  .c-faq {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .c-faq .c-faq__title {
    width: 50%;
    /* padding-right: 40px; */
    display: inline-block;
  }
  .c-faq .c-faq__title::after {
    display: none;
  }
  .c-faq .c-faq__answer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    width: 50%;
    border-left-color: #7371ee;
    border-left-width: thick;
    text-align: justify;
    border-top-color: #f1f2f3;
  }
}

@media (max-width: 780px) {
  .c-faq--active .c-faq__title {
    padding: 0px;
    box-shadow: none;
  }
  .faq-img {
    max-height: 18em;
  }
}
.c-note {
  font-size: 0.8em;
  padding-left: 15px;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}
.c-note:hover {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.c-note a {
  color: #1e88e5;
}

.vol_icon {
  padding-left: 90%;
  padding-top: 0%;
}

.faq-section img {
  height: 60px;
  width: auto;
}
.faq-section {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  flex-basis: 50%;
}
.faq-section h2 {
  font-size: large;
  padding-left: 1rem;
}
.help-heading {
  justify-content: left;
}
.main-images {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 780px) {
  .c-faqs-cont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .left-column,
  .right-column {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .left-column .faq-section,
  .right-column .faq-section {
    display: flex;
    align-items: center;
    width: 50%;
    margin: 10px;
  }

  .right-column .faq-section {
    justify-content: flex-end;
  }

  .faq-section h2 {
    margin: 0;
  }

  .right-column .faq-section h2 {
    width: 200px;
  }

  .icon-container {
    width: 5em;
    text-align: -webkit-center;
  }
}
@media (max-width: 780px) {
  .c-faqs .row {
    justify-content: flex-start;
  }
  .icon-container {
    text-align: center;
    text-align: -webkit-center;
    width: 5em;
  }

  .heading-container {
    width: inherit;
  }
  .faq-section {
    flex-basis: 100%;
  }
}

.searchlist {
  padding: 1em;
  box-shadow: 0px 0px 3px 0px;
  margin: 5px;
}

/* accordion override classes */
.accordion-button:focus {
  z-index: 3;
  border-color: black;
  outline: 0;
  box-shadow: none;
}

.tag_heading {
  display: flex;
  justify-content: first baseline;
  align-items: center;
}

.tag_heading img {
  max-width: 50px;
  max-height: 50px;
  /* width: 50px; */
  margin-right: 20px;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}