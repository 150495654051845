.register {
  overflow: hidden;
  position: relative;
}
.register .container {
  padding: 0px;
}
.register .row {
  display: flex;
  flex-wrap: wrap;
  /* height: 100vh; */
}
.add-margin {
  /* margin-top: 0em; */
  margin-top: 10em !important;
}
.register .col {
  width: 50%;
  position: relative;
  /* margin-top: 130px; */
}

.register .align-items-center {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
}

.register .form-wrapper {
  width: 100%;
  max-width: 28rem;
  margin-top: 90px;
}

.register .form {
  padding: 1rem;
  background-color: var(--white);
  border-radius: 0rem 0rem 1.5rem 1.5rem;
  width: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  transform: scale(0);
  transition: 0.5s ease-in-out;
  transition-delay: 1s;
}

.register .input-group {
  position: relative;
  width: 100%;
  margin: 1rem 0;
  flex-wrap: nowrap;
}

.register .input-group i {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  font-size: 1.4rem;
  color: var(--gray-2);
}

.register .input-group input {
  width: 100%;
  padding: 1rem 3rem;
  font-size: 1rem;
  background-color: var(--gray);
  border-radius: 0register 0.5rem;
  border: 0.125rem solid var(--white);
  outline: none;
}

.register .input-group select {
  width: 100%;
  padding: 1rem 3rem;
  font-size: 1rem;
  background-color: var(--gray);
  border-radius: 0register 0.5rem;
  border: 0.125rem solid var(--white);
  outline: none;
}

.register .input-group input:focus {
  border: 0.125rem solid var(--primary-color);
}

.register .form button {
  cursor: pointer;
  width: 100%;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  border: none;
  background-color: darkcyan;
  color: var(--white);
  font-size: 1.2rem;
  outline: none;
}

.f-input .btn-otp {
  width: auto !important;
  padding: revert !important;
  font-size: inherit !important;
  width: inherit !important;
  border-radius: 0px 20px 20px 0px !important;
}

.register .form p {
  margin: 1rem 0;
  font-size: 1rem;
}

.register .flex-col {
  flex-direction: column;
}

.register .pointer {
  cursor: pointer;
}

.user-board .form {
  background-color: #6f42c1bd;
}

.register .container.sign-in .form.sign-in,
.register .container.sign-in .nav-tabs,
.register .container.sign-in .social-list.sign-in,
.register .container.sign-in .social-list.sign-in > div,
.register .container.user-board .form.user-board,
.register .container.user-board .social-list.user-board,
.register .container.user-board .social-list.user-board > div {
  transform: scale(1);
}

.register .content-row {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 6;
  width: 100%;
  margin-top: 11em;
}

.register .text {
  /* margin: 4rem; */
  color: var(--white);
}

.register .text h2 {
  font-size: 3rem;
  font-weight: 800;
  margin: 2rem 0;
  transition: 1s ease-in-out;
}

.register .text p {
  font-weight: 600;
  transition: 1s ease-in-out;
  transition-delay: 0.2s;
}

.register .img img {
  width: 30vw;
  transition: 1s ease-in-out;
  transition-delay: 0.4s;
}

.register .text.sign-in h2,
.register .text.sign-in p,
.register .img.sign-in img {
  transform: translateX(-250%);
  /* font-size: 3rem; */
  font-weight: 500;
}

.register .text.user-board h2,
.register .text.user-board p,
.register .img.user-board img {
  transform: translateX(250%);
  /* font-size: 3rem; */
  font-weight: 500;
}

.register .container.sign-in .text.sign-in h2,
.register .container.sign-in .text.sign-in p,
.register .container.sign-in .img.sign-in img,
.register .container.user-board .text.user-board h2,
.register .container.user-board .text.user-board p,
.register .container.user-board .img.user-board img {
  transform: translateX(0);
}

/* BACKGROUND */

.register .container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 300vw;
  margin-top: 90px;
  transform: translate(35%, 0);
  background-color: var(--bs-purple);
  transition: 1s ease-in-out;
  z-index: 0;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  opacity: 0.8;
  border-bottom-right-radius: max(50vw, 50vh);
  border-top-left-radius: max(50vw, 50vh);
}
.register .increase-height::before {
  height: 300vh;
}
.register .container.sign-in::before {
  transform: translate(0, 0);
  right: 50%;
}

.register .container.user-board::before {
  transform: translate(100%, 0);
  right: 50%;
}
.f-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.reg_button {
  margin-top: 1rem;
}
.form_span {
  font-size: 12px;
  padding: 3px;
  color: red;
}
/* RESPONSIVE */

@media only screen and (max-width: 770px) {
  .add-margin {
    margin-top: 10em;
  }
  .register .container::before,
  .register .container.sign-in::before,
  .register .container.user-board::before {
    height: 100vh;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    z-index: 0;
    transform: none;
    right: 0;
  }

  /* .register .container.sign-in .col.user-board {
          transform: translateY(-100%);
      } */

  .register .container.sign-in .col.sign-in,
  .register .container.user-board .col.user-board {
    transform: translateY(0);
    margin-top: 10em;
  }

  .register .content-row {
    align-items: flex-start !important;
    overflow: hidden;
  }

  .register .content-row .col {
    transform: translateY(0);
    background-color: unset;
  }

  .register .col {
    width: 100%;
    position: absolute;
    padding: 2rem;
    background-color: var(--white);
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    transform: translateY(-1000%);
    transition: 1s ease-in-out;
  }

  .register .col:nth-child(1) {
    position: relative;
  }
  .register .row {
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 90px;
  }

  .register .form {
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  .register .text {
    margin: 0;
  }

  .register .text p {
    display: none;
  }

  .register .text h2 {
    margin: 0.5rem;
    font-size: 2rem;
  }
  .register .sign-in h2 {
    font-size: 3rem;
    font-weight: 500;
    margin: 2rem 0;
    transition: 1s ease-in-out;
  }
}

#time {
  display: flex;
  justify-content: flex-end;
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 80vh; */
}

.reg_title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: white;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.option {
  margin: 1rem 0;
  font-size: 1.2rem;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  position: relative;
  text-align: center;
  padding: 1.222em 2em 1.111em 2em;
  /* text-transform: uppercase; */
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  text-decoration: none;
  background: darkcyan;
  transform: translate3d(0, 0, 0);
  transition: 0.1s background, 0.1s transform;
  box-shadow: 1px 0px 10px 1px #00000070;
  align-items: center;
  /* justify-content: space-around; */
}

.option i {
  margin-right: 1em;
}

.option:hover {
  /* background: #259d29; */
  transform: translate3d(0, 8px, 0);
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}
/* .option:hover:after, .option:hover:before {
  height: 0;
  background: #208923;
}
.option:after, .option:before {
  content: '';
  position: absolute;
  top: 100%;
  width: 50%;
  height: 8px;
  background: #259d29;
  transition: 0.1s height, 0.1s background;
}
.option:after {
  right: 0;
  transform: skewX(-45deg);
  transform-origin: 0 0;
}
.option:before {
  left: 0;
  transform: skewX(45deg);
  transform-origin: 100% 0;
} */

@media only screen and (max-width: 770px) {
  .user-board .form {
    background-color: white;
  }
  .reg_title {
    color: black;
  }
}

.iconsWrap svg {
  cursor: pointer;
}

.logout-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 1.5em;
  /* margin-top: 3em; */
}

#logout-btn {
  background: darkcyan;
  padding: 0.5em 1em;
  width: auto;
  box-shadow: 1px 0px 10px 1px #00000070;
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: 600;
}

.register .nav {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  transition: 0.5s ease-in-out;
  transition-delay: 1s;
}
.register .tab-content {
  width: 100%;
  max-width: 28rem;
}

.register .nav-item {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.register .nav-item .nav-link {
  justify-content: center;
}
.register .nav-link {
  color: white;
}

.register .nav-link:focus {
  /* color: black; */
  /* border-color: transparent; */
}

.register .nav-tabs .nav-link.active {
  color: var(--color-subtext);
}

@media only screen and (max-width: 770px) {
  .register .nav-item {
    color: black;
  }

  .register .nav-link,
  .register .nav-link:hover {
    color: black;
  }
  /* 
  .register .form-wrapper {
    margin-top: 0px;
  } */
}
