.complaint-form {
  margin-top: 90px;
  background-color: white;
  opacity: 1;
  z-index: 20;
}

.complaint-form p {
  color: grey;
}

#heading {
  text-transform: uppercase;
  color: #009688;
  font-weight: bold;
  font-size: 2rem;
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

.form-card {
  text-align: left;
  background-color: #0096881a;
  padding: 1em;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform input,
#msform select,
#msform textarea {
  padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  /* background-color: #eceff1; */
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #009688;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: #009688;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 10px 10px 5px;
  float: right;
  position: relative;
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #009688;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
  position: relative;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}

.card {
  z-index: 0;
  border: none;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #009688;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.purple-text {
  color: #009688;
  font-weight: normal;
}

.steps {
  font-size: 1.35rem;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}

.fieldlabels {
  color: gray;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
  display: flex;
  justify-content: center;
}

#progressbar .active {
  color: #009688;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 19%;
  float: left;
  position: relative;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

#progressbar #account:before {
  font-family: 'Font Awesome 6 free';
  content: '\f044';
}

#progressbar #personal:before {
  font-family: 'Font Awesome 6 free';
  content: '\f007';
}

#progressbar #payment:before {
  font-family: 'Font Awesome 6 free';
  content: '\f007';
}

#progressbar #confirm:before {
  font-family: 'Font Awesome 6 free';
  content: '\f03e';
}

#progressbar #witness:before {
  font-family: 'Font Awesome 6 free';
  content: '\f2bd';
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #009688;
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: #009688;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}
.error-display {
  font-size: 12px;
  padding: 3px;
  color: red;
  margin-top: -1.5rem;
  margin-bottom: 0.8rem;
}

@media (max-width: 768px) {
  #progressbar li {
    list-style-type: none;
    font-size: 11px;
    width: 19%;
    float: left;
    position: relative;
    font-weight: 400;
  }
}

.microphone-input {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 530px) {
  .microphone-input {
    flex-direction: column;
    align-items: center;
  }
  .microphone-input audio {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.form-inline {
  display: flex;
  flex-direction: column;
  width: max-content;
  flex-basis: 45%;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.form-grid {
  display: inline-flex;
  /* justify-items: stretch; */
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media (max-width: 600px) {
  .form-grid {
    flex-direction: column;
    width: 100%;
  }
  .form-inline {
    width: 100%;
  }
}

.complaint-form table {
  border-bottom-width: 10px;
}

.complaint-form table thead tr {
  color: white;
}

#add_witness_form {
  background-color: #0096881a;
}

#add_evidence_form {
  background-color: #0096881a;
}

#add_witness_form .btn {
  margin: 1em;
}

#add_evidence_form .btn {
  margin: 1em;
}

#add_witness_form .modal-header {
  font-size: 22px;
  margin: 1em;
  margin-top: 0.5em;
}

#add_evidence_form .modal-header {
  font-size: 22px;
  margin: 1em;
  margin-top: 0.5em;
}

.float {
  position: relative;
  /* width: 60px;
  height: 60px; */
  width: fit-content;
  padding: 10px;
  /* top: 110px;
  left: 20px; */
  background-color: #009688;
  color: #fff;
  /* border-radius: 50px; */
  text-align: center;
  box-shadow: 0px -1px 7px 2px #009688;
  /* z-index: 30; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.float i {
  margin-right: .5em;
}

.modal-footer {
  justify-content: space-between;
}