.partners {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  padding-top: 0;
  /* background: var(--color-footer); */
  box-shadow: 0px 0px 20px 0px;
  background-color: whitesmoke;
  margin-top: 70px;
  opacity: 0.9;
}

.partners h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  padding-top: 1em;
  color: var(--color-subtext);
  text-align: center;
}

.partners .container {
  display: flex;
  justify-content: space-evenly;
}

@media screen and (min-width: 1024px) {
  .partners .container {
    max-width: 768px;
  }
}

@media screen and (max-width: 1000px) {
  .partners .container {
    flex-direction: column;
    align-items: center;
  }

  .partners .container .card:nth-child(2) {
    margin-top: -90px;
  }

  .partners .container .card:nth-child(3) {
    margin-top: -90px;
  }
}
.partners .container .card {
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.partners .container .card .face {
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.partners .container .card .face.face1 {
  position: relative;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.partners .container .card:hover .face.face1 {
  background: #009688;
  transform: translateY(0);
  color: white;
}

.partners .container .card .face.face1 .content {
  /* opacity: 0.2;  */
  transition: 0.5s;
  text-align: -webkit-center;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partners .container .card:hover .face.face1 .content {
  opacity: 1;
}

.partners .container .card .face.face1 .content img {
  max-width: 100px;
  padding: 1em;
}

.partners .container .card .face.face1 .content img:nth-child(1) {
  /* scale: 2; */
  -webkit-transform: scale(2); /* android, safari, chrome */
  -moz-transform: scale(2); /* old firefox */
  -o-transform: scale(2); /* old opera */
  -ms-transform: scale(2); /* old IE */
  transform: scale(2); /*standard */
}

.partners .container .card .face.face1 .content img:nth-child(2) {
  /* scale: 3; */
  -webkit-transform: scale(3); /* android, safari, chrome */
  -moz-transform: scale(3); /* old firefox */
  -o-transform: scale(3); /* old opera */
  -ms-transform: scale(3); /* old IE */
  transform: scale(3); /*standard */
}

.partners .container .card .face.face1 .content h3 {
  margin: 10px 0 0;
  padding: 0;
  text-align: center;
  padding: 1em;
}

.partners .container .card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0px 6px rgb(0 0 0 / 80%);
  transform: translateY(-100px);
}

.partners .container .card:hover .face.face2 {
  transform: translateY(0);
}

.partners .container .card .face.face2 .content {
  margin: 0;
  padding: 0;
  text-align: center;
}

.partners .container .card .face.face2 .content h1 {
  margin: 0;
  padding: 0;
}

.partners .container .card .face.face2 .content a {
  margin: 15px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.partners .container .card .face.face2 .content a:hover {
  background: #009688;
  color: #fff;
}
