.about_heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2em;
}
/* .about_heading h1 {
  font-size: 48px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  max-width: 510px;
  color: var(--color-subtext);
} */
.about_heading p {
  margin-top: 2rem;
  font-size: 1.2rem;
  text-align: justify;
}
.about_closing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 2rem;
}
.about_closing p {
  margin-left: 4rem;
  margin-right: 4rem;
  font-size: 1.2rem;
}
.about_us_container_col h1 {
  font-size: 20px;
  font-family: var(--font-family);
}
.about_us_container_col p {
  font-size: 16px;
  font-family: var(--font-family);
  color: black;
}
.about_us_container_col {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 2rem;
  position: relative;
}
.about_us_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/*for About Heading*/
.about_main {
  display: flex;
  justify-content: center;
  text-align: center;
  /* min-height: 220px; */
  padding-bottom: 0;
  margin-bottom: 0;
  overflow: hidden;
  margin-top: 70px;
}

.about_main-content {
  padding: 5rem;
  z-index: 5;
}

.about_main h1 {
  font-weight: 600;
  padding-top: 10px;
  font-size: 4rem;
  color: white;
  text-shadow: 0 2px 5px rgb(0 0 0 / 40%);
}

.about_main h2 {
  font-weight: 600;
  color: var(--primary-green);
  text-shadow: 0 2px 5px rgb(0 0 0 / 40%);
  font-size: x-large;
  margin-bottom: 1em;
}

.about_main p {
  font-weight: 400;
  font-size: 18px;
  margin-top: 1.5rem;
}

@media screen and (max-width: 850px) {
  .features-container__feature {
    padding-bottom: 2rem;
  }
}

/* new about text*/

.about_main .container {
  position: absolute;
  max-width: 800px;
  height: 600px;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about_sec .text p {
  text-align: justify;
  font-size: large;
}

.card_shadow {
  box-shadow: 0px 0px 5px 0px;
  margin: 1em;
}

.sidectn2 {
  display: none;
}

.sidectn5 {
  display: none;
}
.sidectn3 {
  display: none;
}
.sidectn4 {
  display: none;
}

.about-container ul, li{
  list-style: revert;
  list-style-type: revert;
  padding: revert;
}

.about-container a {
  text-decoration: revert;
  color: revert;
}

.about-container .show {
  max-height: 3000px;
  opacity: 1;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in;
}

.about-container .hide {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0s ease-out, opacity 0s ease-out;
}