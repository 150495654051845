.about_sec {
  display: flex;
  flex-direction: column;

  padding: 1em;
  padding-top: 0;
  /* background: var(--color-footer); */
  box-shadow: 0px 0px 20px 0px;
  background-color: white;
  margin-top: 70px;
  /* opacity: 0.9; */
  overflow: hidden;
  /* ff 3.6+ */
  /* background: -moz-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  /* background: -webkit-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  ); */

  /* opera 11.10+ */
  /* background: -o-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  ); */

  /* ie 10+ */
  /* background: -ms-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  ); */

  /* global 92%+ browsers support */
  /* background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  ); */

  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.about_sec_heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.about_sec_heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  max-width: 510px;
  padding: 1em;
}

.about_sec_heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-subtext);
  cursor: pointer;
}

.about_us_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 2rem;
}

.about_us_container .features-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}
.about_sec_heading h1 {
  color: var(--color-subtext);
}

.about_us_container .features-container_feature-text {
  margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
  .about_sec_heading {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;
  }

  .about_sec_heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .about_sec_heading h1 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (max-width: 350px) {
  .about_us_container .features-container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }
}

.about_container {
  /* height: 25em; */
  display: flex;
}

.about_container .content {
  align-items: stretch;
  padding: 2em;
  text-align: justify;
}

/* @media screen and (max-width: 1000px) {
  .about_container {
  flex-direction: column;
  }
  .about_container iframe{
    width: 100%;
    }
} */

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

.video {
  padding: 2em;
  max-width: 40em;
}
/* .row:after {
  content: "";
  display: table;
  clear: both;
} */

@media screen and (max-width: 1000px) {
  .column {
    width: 100%;
  }
  /* .about_container .content {
    padding: 10px;
  } */
  .about_container .video {
    height: 25em;
  }
  /* .video {
    padding: 0px;
  } */
}
