.header {
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  transition: 0.3s ease-in;
}

.nav-colored {
  background-color: #7b1fa2;
}

.nav-transparent {
  background-color: transparent;
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.header .nav-menu .navigator {
  color: #ffffff;
  cursor: pointer;
}

.header .navbar img {
  /* width: 250px; */
  width: auto;
  height: 87px;
}

.header .nav-menu {
  display: flex;
  margin-bottom: 0px;
}

.header .nav-item {
  /* padding: 1rem; */
  font-weight: 700;
}
.navigator {
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav-item:hover .navigator {
  color: #ffc107;
}

.hamburger {
  display: none;
}
.nav-item .navigator {
  text-decoration: none;
}
.btn_spacing {
  margin-left: 1rem;
}

@media screen and (max-width: 1000px) {
  .header {
    max-width: 100%;
  }

  .header .navbar {
    max-width: 100%;
  }

  .hamburger {
    display: block;
  }
  .nav__submenu {
    display: none;
  }

  .nav-menu {
    position: fixed;
    left: -100%;
    top: 90px;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    z-index: 999;
    text-align: center;
    transition: 0.3s;
    background-color: black;
  }

  .nav-menu.nav_active {
    left: 0;
    padding: 0px;
    overflow: auto;
  }

  .header .nav-item {
    margin: 9.5px 52px;
  }

  .header .navbar img {
    /* width: 150px; */
    width: 100px;
  }
}

/* CSS for Dropdown */

.nav__submenu {
  display: none;
}
.nav__menu-item:hover .nav__submenu {
  display: block;
  border-radius: 10px;
}

nav ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.nav__submenu-item {
  display: block;
  padding: 0 16px;
  line-height: inherit;
  cursor: pointer;
}
.nav-menu {
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
}
.nav__menu-item {
  display: inline-block;
  position: relative;
}

.nav__menu-item:hover .nav__submenu {
  display: block;
}
.nav__submenu {
  color: black;
  font-weight: bold;
  text-transform: none;
  display: none;
  position: absolute;
  width: max-content;
  z-index: 100;
  background-color: #ffc107;
}
.nav__submenu-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1000px) {
  .nav__submenu {
    display: none;
    flex-direction: column;
    position: relative;
  }
}
