.sidebar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vid .sidebar-content {
display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}