.blog-container {
  margin-top: 90px;
}

:root {
  --white: #fff;
  --black: #000;
  --dark: #2a2a2e;
  --yellow: #f7b500;
  --darkyellow: #f79300;
  --red: #fe3e30;
  --darkred: #f72729;
  --blue: #2588cf;
  --darkblue: #026dbe;
  --defaultfont: 'Poppins', sans-serif;
  --titlefont: 'Roboto', sans-serif;
}

/* ::-webkit-scrollbar {
  height: 12px;
  width: 6px;
  background: var(--dark);
}

::-webkit-scrollbar-thumb {
  background: var(--dark);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: var(--dark);
} */

/********************************
              DEFAULT
    *********************************/
body {
  margin: 0;
  overflow-x: hidden !important;
  font-family: var(--defaultfont);
}

a {
  text-decoration: none;
  color: inherit;
}

a,
button,
input,
select,
p {
  outline: none !important;
  transition: 0.5s;
}

em {
  font-style: normal;
  color: var(--primary);
}

p {
  line-height: 1.6em;
  font-size: 14px;
  color: rgba(1, 1, 1, 0.7);
  margin: 0px;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
  padding: 0;
}

fieldset {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}

.title {
  font-family: var(--titlefont);
}

.btn1,
.btn2 {
  padding: 1rem 2rem;
  border-radius: 10px;
  text-align: center;
  border: 0;
  z-index: 10;
  position: relative;
}

.btn1 {
  background-color: var(--yellow);
  color: var(--white);
}

.btn1:hover {
  background-color: var(--darkyellow);
}

.btn2 {
  background-color: var(--red);
  color: var(--white);
}

.btn2:hover {
  background-color: var(--darkred);
}

/********************************
            BLOG CONTAINER
    *********************************/
.blog_container {
  width: 100%;
  display: flex;
  align-items: top;
  margin-top: 90px;
  background-color: #f1f1f1;
}

/*BLOG LEFT CONTENT*/
.blog_content {
  padding: 2rem;
  width: 100%;
}

.blog_content .load-btn {
  display: block;
  width: 150px;
  margin: 5vh auto;
  background-color: #7371ee;
}

.left_content {
  display: flex;
  align-items: top;
  justify-content: space-evenly;
  flex-wrap: wrap;
  column-count: 3;
  gap: 20px 10px;
  flex: 0 0 70%;
}
@media (min-width: 40rem) {
  .blog-container .card {
    width: 50%;
  }
}
@media (min-width: 56rem) {
  .blog-container .card {
    width: 33.33%;
  }
}

.right_content {
  flex: 0 0 30%;
}

/*BLOG RIGHT CONTENT*/
.columns {
  display: block;
  margin-bottom: 4vh;
  background-color: var(--white);
  z-index: 2;
  position: relative;
}

.columns section {
  padding: 1rem;
}

.columns .title {
  background-color: #41c241;
  color: var(--white);
  padding: 1rem;
  text-align: left;
  width: 100%;
  display: block;
  transition: 0.2s;
  border-left: 0px solid var(--dark);
}

.columns:hover > .title {
  border-left: 5px solid var(--dark);
}

.columns .title a {
  float: right;
}

.columns .title a:hover {
  color: var(--dark);
}

/*CATEGORIES*/
.categories a {
  display: inline-block;
  padding: 0.2rem 1rem;
  border-radius: 40px;
  background-color: cadetblue;
  margin: 5px 3px;
  font-size: 12px;
  white-space: nowrap;
  color: var(--white);
}

.categories a:hover {
  background-color: #009688;
}

/*POSTS*/
.posts a {
  display: flex;
  align-items: center;
  margin: 0.4rem 0;
}

.posts a img {
  width: 100px;
  margin-right: 10px;
  height: 70px;
}

.posts a:hover > p {
  color: var(--black);
}

@keyframes marquee1 {
  0% {
    top: 10%;
  }
  100% {
    top: -100%;
  }
}

@media (max-width: 1000px) {
  .blog_container {
    flex-wrap: wrap;
  }
  .blog_content {
    padding: 0;
    order: 2;
  }
  .left_content {
    flex: 0 0 100%;
    order: 2;
    padding: 1rem;
  }
  .right_content {
    flex: 0 0 100%;
    order: 1;
    padding: 1rem;
  }
  .posts,
  .categories {
    display: inline-block;
    width: 47%;
    margin: 1.3%;
    margin-bottom: 0;
    vertical-align: top;
    height: 63vh;
  }
  .posts {
    overflow-y: auto;
  }
  .right_content {
    flex: 0 0 100%;
  }
}

@media (max-width: 740px) {
  .posts,
  .categories {
    width: 100%;
    margin: 0;
    height: auto;
    margin-bottom: 4vh;
  }
}

.fw-5 {
  font-weight: 500;
}
.fw-7 {
  font-weight: 700;
}
main {
  width: 800px;
  box-sizing: border-box;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 14px #80808047;
}
.blog-container .card {
  display: flex;
  flex-direction: column;
  width: 22.5em;
  /* box-shadow: 0px 0px 2px 0px; */
  /* height: -moz-fit-content;
  height: fit-content; */
}
.blog-container .card .card-left {
  background-color: #efefef;
  height: 16em;
  border: 4px solid white;
}
.blog-container .card .card-left .card-img {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.blog-container .card .card-left .card-img::before {
  content: '';
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  border-radius: 8px 0px 0px 8px;
}
.blog-container .card .card-left .card-img img {
  width: 100%;
}
.blog-container .card .card-right {
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  height: 16em;
}
.blog-container .card .card-right .card-title {
  font-size: 20px;
  color: hsl(214, 17%, 51%);
}
.blog-container .card .card-right .card-text {
  font-size: 14px;
  color: hsl(212, 23%, 69%);
  margin-top: 20px;
}
.blog-container .card .card-right .card-right-body {
  display: flex;
  margin-top: auto;
}
.blog-container .card .card-right .card-right-body .card-rb-1 {
  flex-grow: 1;
  text-align: center;
  align-self: center;
}
.blog-container .card .card-right .card-right-body .card-rb-1 i {
  width: 45px;
  border-radius: 50%;
}
.blog-container .card .card-right .card-right-body .card-rb-2 {
  flex-grow: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 3px;
}
.blog-container .card .card-right .card-right-body .card-rb-2 .card-rb-2-title {
  color: hsl(214, 17%, 51%);
}
.blog-container .card .card-right .card-right-body .card-rb-2 .card-rb-2-text {
  color: hsl(212, 23%, 69%);
}
.blog-container .card .card-right .card-right-body .card-rb-3 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.blog-container .card .card-right .card-right-body .card-rb-3 .card-rb-3-inner {
  background: url(https://rvs-article-preview-component.netlify.app/images/icon-share.svg)
    hsl(210, 46%, 95%);
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
}
.blog-container
  .card
  .card-right
  .card-right-body
  .card-rb-3
  .card-rb-3-inner:hover {
  background: url(https://rvs-article-preview-component.netlify.app/images/icon-share-white.svg)
    hsl(217, 19%, 35%);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}
.blog-container
  .card
  .card-right
  .card-right-body
  .card-rb-3
  .card-rb-3-inner-before {
  position: absolute;
  width: 275px;
  height: 55px;
  background: hsl(217, 19%, 35%);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  color: white;
  box-sizing: border-box;
  align-items: center;
  top: -80px;
  left: -110px;
  opacity: 0;
  justify-content: center;
  padding: 0px 20px;
  z-index: 2;
}
.blog-container
  .card
  .card-right
  .card-right-body
  .card-rb-3
  .card-rb-3-inner-before::after {
  content: '';
  position: absolute;
  top: 40px;
  left: 50%;
  width: 40px;
  height: 40px;
  background: hsl(217, 19%, 35%);
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
}
.card
  .card-right
  .card-right-body
  .card-rb-3
  .card-rb-3-inner:hover
  + .card-rb-3-inner-before {
  opacity: 1;
}
.before-1 {
  flex-grow: 2;
  letter-spacing: 7px;
  color: hsl(212, 23%, 69%);
  display: flex;
  justify-content: center;
}
.before-2 {
  flex-grow: 1;
}
.before-3 {
  flex-grow: 1;
}
.before-4 {
  flex-grow: 1;
}

@media screen and (max-width: 850px) {
  body {
    height: 100%;
    margin: 70px auto;
  }
  main {
    width: 86%;
  }
  .blog-container .card {
    flex-direction: column;
  }
  .blog-container .card .card-left {
    width: 100%;
  }
  .blog-container .card .card-right {
    width: 100%;
    padding: 40px 25px 20px;
  }
  .blog-container .card .card-left .card-img {
    /* height: 210px; */
  }
  .blog-container .card .card-left .card-img::before {
    border-radius: 8px 8px 0px 0px;
  }
  .blog-container
    .card
    .card-right
    .card-right-body
    .card-rb-3
    .card-rb-3-inner-before {
    top: -3px;
    left: -235px;
    width: 230px;
  }
  .blog-container
    .card
    .card-right
    .card-right-body
    .card-rb-3
    .card-rb-3-inner-before::after {
    content: unset;
  }
}

.archives li {
  list-style: square;
}

.back-btn {
  width: min-content;
  padding-left: 0.5em;
  z-index: 10;
  position: absolute;
  cursor: pointer;
  text-decoration: underline;
}

.blog-content {
  position: relative;
  z-index: 5;
  padding-top: 1.5em;
  width: 100%;
}

.card .share-btn {
  position: absolute;
  right: 0px;
}

.card .share-icons {
  bottom: 0px;
  right: 0px;
}
