.resource {
  margin-top: 6em;
  display: flex;
  /* background-image: url("./images/ResourceBg.jpg"); */
  background-color: #f1f1f1;
}

.resource .row {
  width: 100%;
  padding: 2em;
}

.doc .order-first {
  max-height: 87vh;
}

.doc .order-last {
  height: 86vh;
}

.vid .order-first {
  height: max-content;
}

.vid .order-last {
  max-height: 86vh;
}

.order-first {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* max-height: 95vh; */
}

.order-first h3 {
  display: none;
}
.order-last {
  /* background-color: #008080; */
  /* max-height: 95vh; */
  overflow: overlay;
  padding: 0px;
  /* -ms-overflow-style: none; Internet Explorer 10+ */
  /* scrollbar-width: none; Firefox */
  animation: 0.5s ease 0s 1 element-opacity;
  display: block;
  box-shadow: 0px 0px 4px 0px #181b1ebf;
  /* /* border: 1px solid #008080; */
}

@keyframes element-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .order-last::-webkit-scrollbar {
  display: none; /*Safari and Chrome*/
/* } */

ul,
li {
  list-style: none;
}
.sidebar h4 {
  /* margin: 10px 0px 15px; */
  /* padding-bottom: 10px;
    padding-left: 10px;
    padding-top: 5px; */
  /* border-left: 5px solid var(--bs-green); */
  /* position: fixed; */
  /* background-color: #a8ccf1; */
  width: 100%;
  font-family: var(--titlefont);
  font-weight: 600;
  padding: 0.4em;
  font-size: x-large;
  color: darkgreen;
  background-color: lightgreen;
}

.sidebar.widget {
  background: #f2f2f2;
  /* border: 1px solid #ddd; */
  animation: 0.5s ease 0s 1 element-translate;
  position: relative;
  padding-bottom: 1em;
}

@keyframes element-translate {
  0% {
    transform: translateY(40px);
  }
  100% {
    transform: translateY(0px);
  }
}
.sidebar.widget ul {
  margin: 0px;
  padding: 0;
  /* overflow: hidden; */
  /* margin-top: 4em; */
}
.sidebar.widget ul li {
  /* overflow: hidden; */
  font-size: 14px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ddd;
  padding: 10px 20px;
  box-shadow: 0px 0px 7px 1px #181b1ebf;
    /* transform: skewY(10deg); */
    width: 85%;
    margin: 1em;
    outline-style: solid;
}


.sidebar-thumb {
  float: left;
  overflow: hidden;
  /* margin-right: 15px; */
  min-width: 30%;
  display: flex;
  align-items: center;
}
.sidebar-thumb img {
  /* background: #fff;  */
  /* border: 1px dashed #e0e0e0; */
  /* padding: 6px; */
  height: 70px;
  width: 70px;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.sidebar-content h5 {
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
  /* padding: 1em 0px; */
}

.sidebar-meta {
  margin-top: 10px;
  text-align: end;
}
.sidebar-meta span {
  color: #2e2e2e;
}
.sidebar-meta span.time {
  /* margin-right: 10px; */
}
.sidebar-meta span i {
  color: #2996bd;
  margin-right: 1em;
  margin-left: 3em;
}

:root {
  --background: #4285f4;
  --icon-color: #344955;
  --width: 50px;
  --height: 50px;
  --border-radius: 100%;
}
.wrapper {
  position: fixed;
  border-radius: var(--border-radius);
  justify-content: center;
  align-items: center;
  bottom: 1em;
  right: 1em;
  z-index: 20000;
  display: none;
}
.wrapper .fas {
  color: darkcyan;
  width: var(--width);
  height: var(--height);
  position: absolute;
  z-index: 3;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fab-animation-reverse 0.4s ease-out forwards;
  bottom: 0em;
  right: 0em;
  font-size: 45px;
}

.wrapper input {
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
  cursor: pointer;
  position: absolute;
  z-index: 5;
  opacity: 0;
}
.wrapper input:checked ~ .fas {
  animation: fab-animation 0.4s ease-out forwards;
}

.resource-video {
  margin: 5em;
  margin-bottom: 1em;
}

.video-container {
  height: 100%;
  width: 100%;
  margin: 0.5em;
  align-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1em;
  box-shadow: 0px 0px 4px 0px #181b1ebf;
}

.video-container div {
  margin: 5em;
  margin-top: 0em;
}

@keyframes fab-animation {
  0% {
    transform: rotate(0) scale(1);
  }
  20% {
    transform: rotate(60deg) scale(0.93);
  }
  55% {
    transform: rotate(35deg) scale(0.97);
  }
  80% {
    transform: rotate(48deg) scale(0.94);
  }
  100% {
    transform: rotate(45deg) scale(0.95);
  }
}
@keyframes fab-animation-reverse {
  0% {
    transform: rotate(45deg) scale(0.95);
  }
  20% {
    transform: rotate(-15deg);
  }
  55% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}

@media screen and (max-width: 1000px) {
  iframe {
    width: inherit;
    margin: 0em;
  }
  .video-container {
    margin: 0em;
    padding: 0em;
  }
  .video-container div {
    margin: 0em;
    padding: 0em;
    padding-bottom: 2em;
  }
  .video-container div p{
    margin: 0em 1em;
  }
  .resource-video {
    margin: 0em;
  }
  .order-last {
    display: none;
    position: absolute;
    width: 100%;
    overflow: overlay;
    background-color: #008080;
  }
  .order-first {
    display: block;
  }
  .order-first .resource-doc {
    height: 74vh;
  }
  .col-8 {
    width: 100%;
  }
  .row {
    margin-left: 0px;
  }

  .fac ul {
    width: max-content;
  }
  .wrapper {
    display: flex;
  }

  .order-first h3 {
    display: block;
    position: relative;
    padding: 15px;
    font-weight: 700;
  }
}

@media screen and (max-width: 400px) {
  .resource .row {
    padding: 0.5em;
  }
  .doc .order-first {
    max-height: max-content;
  }

  .doc .order-last {
    max-height: 87vh;
  }
}

.video-container p {
  font: caption;
  font-weight: 700;
  white-space: pre-wrap;
}
