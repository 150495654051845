.main {
  /* height: 100vh; */
  width: 100vw;
  /* background: deeppink; */
  /* clip-path: polygon(0 0, 100% 0, 100% 68%, 20% 100%, 0 100%); */
  box-shadow: 0px 20px 20px -13px;
  /* transform: scaleX(1.5); */
  position: relative;
  overflow: hidden;
  margin-top: 70px;
}

.main svg {
  bottom: 0px;
  position: relative;
  padding: 0px;
  /* margin: -17px; */
  margin-top: -2em;
}

.main .main-content {
  padding: 8em;
  padding-bottom: 0.5em;
  z-index: 5;
  position: relative;
  /* margin-right: 26em; */
  display: flex;
}

.main_heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main_heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  /* max-width: 510px; */
  padding: 1em;
  color: white;
  text-align: center;
}

.main h2 {
  font-weight: 600;
  color: var(--primary-green);
  text-shadow: 0 2px 5px rgb(0 0 0 / 40%);
  font-size: x-large;
  margin-bottom: 1em;
}

#main-p {
  font-size: x-large;
}

.main .device {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15em;
  bottom: 3em;
}
.main .btn-complaint {
  font-weight: bold;
  position: relative;
}
.main .main-content p {
  font-size: 20px;
}

@media screen and (max-width: 850px) {
  .main {
    /* height: 88vh; */
  }

  .main .main-content {
    margin-right: 0px;
  }
  #main-p {
    font-size: larger;
  }
}

@media screen and (max-width: 940px) {
  .main .main-content {
    padding: 7em;
    padding-bottom: 0.5em;
    padding-left: 5em;
  }
}

@media screen and (max-width: 1290px) {
  .main .device {
    padding-right: 8em;
  }
}

@media screen and (max-width: 560px) {
  .main .device {
    padding-right: 6em;
    bottom: 1em;
  }
  .main h1 {
    font-size: 26px;
  }
  .main h2 {
    font-size: 18px;
  }

  .main .main-content {
    padding: 2em;
    padding-bottom: 0.5em;
  }
  .main .main-content p {
    font-size: 15px;
  }

  .btn-complaint {
    font-size: 14px;
  }
}

.btn-complaint {
  position: absolute;
  border-radius: 15px;
  padding: 0.5em 1em;
  background-color: #7371ee;
  border: none;
  box-shadow: 3px 4px 5px 0px black;
  z-index: 5;
  color: white;
}

.btn-complaint:hover {
  transform: translateY(-2px);
}

/* phone */

.iphone-x {
  /* margin: 40px auto; */
  width: 16em;
  height: 28em;
  background-color: #7371ee;
  /* background-image: url("images/MFFLogo.jpg"); */
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 40px;
  bottom: 0em;
  background-position-y: center;
  position: absolute;
  box-shadow: 0px 0px 0px 10px rgb(140 148 159 / 47%);
  transform: scale(1) rotateZ(349deg);
  transform-origin: top;
}
.iphone-x:before,
.iphone-x:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.iphone-x:after {
  bottom: 7px;
  width: 140px;
  height: 4px;
  background-color: #f2f2f2;
  border-radius: 10px;
}
.iphone-x:before {
  top: 0px;
  width: 56%;
  height: 30px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}
.iphone-x i,
.iphone-x b,
.iphone-x s,
.iphone-x span {
  position: absolute;
  display: block;
  color: transparent;
}
.iphone-x i {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
}
.iphone-x b {
  left: 10%;
  top: 0px;
  transform: translate(180px, 4px);
  width: 12px;
  height: 12px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
}
.iphone-x b:after {
  content: "";
  position: absolute;
  background-color: #2d4d76;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
  top: 3px;
  left: 3px;
  display: block;
  border-radius: 4px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}
.iphone-x s {
  top: 50px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  width: 100%;
  font-size: 70px;
  font-weight: 100;
  padding-top: 60px;
}
.iphone-x span {
  bottom: 50px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  left: 30px;
}
.iphone-x span + span {
  left: auto;
  right: 30px;
}

@media screen and (max-width: 1290px) {
  .iphone-x {
    transform: scale(0.8) rotateZ(349deg);
    bottom: -4em;
  }
}

@media screen and (max-width: 768px) {
  .iphone-x {
    transform: scale(0.7) rotateZ(349deg);
    bottom: -9em;
    display: none;
  }
}

/* @media screen and (max-width: 420px) {
    .iphone-x {
        bottom: -5em;
      }
  } */
